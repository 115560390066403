<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'sData'],
  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    sData: {
      immediate: true,
      deep: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.sData = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      // console.log(this.sData, 'sData')
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        normal: {
          top: 200,
          left: 300,
          width: 500,
          height: 400,
          zIndex: 6,
          backgroundColor: ''
        },
        color: ['rgba(245, 166, 35, 1)', 'rgba(19, 173, 255, 1)'],
        title: {
          show: true,
          text: '',
          left: 'center',
          top: '1%',
          textStyle: {
            fontSize: 18,
            color: '#293C55',
            fontStyle: 'normal',
            fontWeight: 'normal'
          }
        },
        radar: {
          center: ['50%', '50%'],
          radius: '70%',
          startAngle: 90,
          splitNumber: 4,
          shape: 'circle',
          splitArea: {
            areaStyle: {
              color: ['transparent']
            }
          },
          axisLabel: {
            show: false,
            fontSize: 18,
            color: '#fff',
            fontStyle: 'normal',
            fontWeight: 'normal'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#A5B6BE'//
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'grey'//
            }
          },
          indicator: this.sData.typelist
        },
        series: [{
          name: '',
          type: 'radar',
          symbol: 'circle',
          symbolSize: 5,
          areaStyle: {
            normal: {
              color: 'rgba(245, 166, 35, 0.4)'
            }
          },
          lineStyle: {
            normal: {
              color: 'rgba(245, 166, 35, 1)',
              width: 1
            }
          },
          data: [
            this.sData.data
          ]
        }
        // , {
        //   name: this.sData.name[1],
        //   type: 'radar',
        //   symbol: 'circle',
        //   symbolSize: 5,

        //   areaStyle: {
        //     normal: {
        //       color: 'rgba(245, 166, 35, 0.4)'
        //     }
        //   },
        //   lineStyle: {
        //     normal: {
        //       color: 'rgba(245, 166, 35, 1)',
        //       width: 1
        //     }
        //   },
        //   data: [
        //     this.sData.data
        //   ]
        // }
        ]
      })
    }
  }
}
</script>
